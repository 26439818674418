<template>
  <div class="replay">
    <h1 style="text-align: left;">观看所有VDS活动回放</h1>
    <div v-for="(video, index) in videos" :key="index" class="video-card">
      <img :src="video.cover" alt="视频封面" class="video-cover">
      <div class="video-info">
        <h2>{{ video.title }}</h2>
        <div class="video-meta">
  <p>{{ formatDuration(video.duration) }}，{{ video.description }}</p>
  <span>{{ formatDate(video.date) }}</span>
</div>
      </div>

        <button class="play-button" @click="linkToVideoById(video.vid)">播放</button>

    </div>
  </div>
</template>

<script>
export default {
  name: 'vc-AllVDSEvents',
  data() {
    return {
      videos: []
    };
  },
  mounted() {
    document.title = "活动回放 - VDS·欢乐，正发生。";

    // 使用 fetch 从 API 获取视频列表
    fetch('https://static.vdsentnet.com/pubapi/list_videos.php')
      .then(response => response.json())
      .then(data => {
        this.videos = data.reverse(); // 倒序插入数据
      })
      .catch(error => {
        console.error('Error fetching video list:', error);
      });
  },
  methods: {
    linkToVideoById(vid) {
      this.$router.push({ path: '/vds-events/replay/play', query: { vid: vid } });
    },
    formatDuration(seconds) {
      seconds = Number(seconds);
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      let result = '';
      if (hrs > 0) {
        result += `${hrs}小时`;
      }
      if (mins > 0) {
        result += `${mins}分钟`;
      }
      if (secs > 0) {
        result += `${secs}秒`;
      }
      if (result === '') {
        result = '0秒';
      }
      return result;
    },
    formatDate(dateString) {
      const dateParts = dateString.split('-');
      if (dateParts.length !== 3) {
        return dateString;
      }
      const year = dateParts[0];
      const month = dateParts[1].replace(/^0/, '');
      const day = dateParts[2].replace(/^0/, '');

      return `${year}年${month}月${day}日`;
    },
  }
};
</script>

<style scoped>
.replay {
  color: white;
  padding: 20px 50px;
}

.video-card {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: space-between;
  margin-bottom: 20px;
  height: 150px;
}

.video-cover {
  width: 150px;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px;
}
.video-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.play-button {
  background-color: #006aff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px;
  white-space: nowrap; /* 防止文字换行 */
  width: 100px; /* 设置固定宽度 */
  height: 40px; /* 设置固定高度 */
}


/* 手机端适配 */
@media (max-width: 768px) {
  .replay {
    padding: 10px 20px; /* 减少内边距 */
  }

  .video-card {
    flex-direction: column; /* 垂直堆叠 */
    height: auto; /* 自动调整高度 */
    text-align: center; /* 居中对齐内容 */
  }

  .video-cover {
    width: 100%; /* 占据100%宽度 */
    max-width: 200px; /* 最大宽度限制 */
    margin: 0 auto 10px; /* 居中并在下方留出间距 */
  }

  .video-info {
    text-align: center; /* 居中文本 */
  }

  .play-button {
  background-color: #006aff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px;
  white-space: nowrap; /* 防止文字换行 */
  width: 100px; /* 设置固定宽度 */
  height: 40px; /* 设置固定高度 */
}
}
</style>
