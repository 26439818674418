<template>
    <div class="container">
        <div class="content">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 350 459.4" fill="#FFFFFF">
                <path class="cls-1" d="M112.9,348.5L5.5,51.4h32.5l89.8,252.8h2.4L219.9,51.4h32.5l-107.8,297.1h-31.8Z"/>
                <path class="cls-1" d="M162.6,349.2v-122.8h37.9c12.7,0,23.6,2.5,32.6,7.4,9,4.9,15.8,12,20.5,21.2,4.7,9.2,7,20.2,7,32.8s-2.5,23.6-7.4,32.8c-4.9,9.2-12,16.3-21.4,21.2-9.3,4.9-20.6,7.4-33.8,7.4h-35.5ZM198,337.4c16,0,28.2-4.3,36.8-12.8s12.8-20.8,12.8-36.8-4.2-28.2-12.5-36.8c-8.3-8.5-20.2-12.8-35.8-12.8h-24.1v99.1h22.8Z"/>
                <path class="cls-1" d="M284.7,345.4c-7.6-4-13.7-9.9-18.2-17.6l10.7-6.5c3.6,6,8.2,10.6,13.9,13.7,5.7,3.1,12.1,4.6,19.2,4.6s10.4-.9,14.8-2.8c4.4-1.8,7.8-4.4,10.3-7.7,2.5-3.3,3.7-7,3.7-11.1s-1.1-7.9-3.2-10.9c-2.2-2.9-5.5-5.5-10-7.7-4.5-2.2-10.6-4.4-18.4-6.6-8.2-2.4-14.9-4.8-20-7.1-5.1-2.4-9.4-5.8-12.9-10.3-3.5-4.5-5.2-10.3-5.2-17.6s1.7-12.3,5.2-17.5c3.5-5.2,8.3-9.2,14.6-12.1,6.3-2.9,13.4-4.3,21.5-4.3s16.6,1.8,23.2,5.5c6.6,3.7,12.1,8.8,16.4,15.4l-9.9,7.1c-3.7-5-8.1-8.9-13.4-11.8-5.2-2.9-10.8-4.4-16.8-4.4s-9.9.9-14.3,2.8c-4.3,1.8-7.7,4.4-10.2,7.6-2.5,3.2-3.7,6.8-3.7,10.7s1.4,9,4.1,12.2c2.8,3.2,6.2,5.6,10.4,7.4,4.2,1.7,9.8,3.5,16.7,5.3,8.1,2.3,14.9,4.8,20.3,7.5,5.5,2.7,9.8,6.3,13.1,10.9,3.3,4.5,4.9,10.3,4.9,17.2s-1.7,12.9-5.1,18.1c-3.4,5.1-8.3,9.1-14.6,11.8s-13.7,4.1-22.1,4.1-17.7-2-25.4-6.1Z"/>
        </svg>
            <div class="text">
                <h1>404 Not Found</h1>
                <p>页面未找到</p>
                <p style="color: gray;">很抱歉，你请求的页面不存在，这不是你的问题。可能导致此问题的原因包括页面已被我们删除、链接地址错误或页面暂时不可用。</p>
                <p style="color: gray;">一只毛茸茸的小伙伴在寻找正确的房间串门，但房间就是找不到，他又看了一下朋友发给他的房号，写着404。</p>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'vc-Error404',
    mounted() {
        document.title = "这个页面似乎不存在。 - VDS·欢乐，正发生。";
    }
  };
  </script>
  
  <style scoped>
.container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

svg {
    fill: #ffffff;
    max-width: 100px;
    margin-right: 20px;
}

.content {
    display: flex;
    align-items: center;
}

.text {
    font-size: 18px;
    color: #ffffff;
}

.text h1 {
    font-size: 48px;
    margin: 0;
}

.text p {
    margin: 5px 0;
}

.explanation, .joke {
    margin-top: 20px;
    font-size: 16px;
    color: #555;
}

.explanation {
    margin-bottom: 10px;
}
  </style>  