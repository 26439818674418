<template>
    <div class="scroll-container">
      <div class="card-container" id="card-container">
        <div
          class="card"
          style="background-image: url('./img/Home/always-background.jpg');"
          data-title="欢乐，正发生"
          data-subtitle="欢迎访问vds.pub。"
          data-align="center"
        ></div>
        <!--NextVersion
        <div
          class="card"
          style="background-image: url('./img/Home/nextupdate-ls20.png');"
          data-title="VDS LiveStream 2.0"
          data-subtitle="看见下一场飞跃。"
          data-align="bottom-left"
          data-badge="comingsoon"
          data-link="/services/livestream"
        >
        </div>-->
        <div
          class="card"
          style="background-image: url('./img/Home/240906update-qae14.png');"
          data-title="Q&ATime 第14期"
          data-subtitle="在9月1号我们更新了最新的Q&A节目，在节目中回答了很多逆天问题😂，希望你喜欢。"
          data-align="bottom-left"
          data-badge="latest"
        ></div>
        <div
          class="card"
          style="background-image: url('./img/Home/240906update-smzxb.png');"
          data-title="数码资讯榜2413期由我们主持"
          data-subtitle="欢迎留意收看。"
          data-align="bottom-left"
          data-badge="news"
        ></div>
      </div>
    </div>
  
    <!-- 滚动指示器 -->
    <div class="indicator-container" id="indicator-container"></div>
  </template>
  
  <script>
export default {
  name: "vc-home",
  mounted() {
    this.setCardHeight();
    this.initializeCards();

    document.title = "VDS·欢乐，正发生。";

    const cardContainer = document.getElementById('card-container');
    const indicators = document.getElementById('indicator-container');
    const cards = cardContainer.querySelectorAll('.card');

    // 创建圆点指示器并添加点击事件
    cards.forEach((_, index) => {
      const indicator = document.createElement('div');
      indicator.classList.add('indicator');
      if (index === 0) indicator.classList.add('active');
      indicators.appendChild(indicator);

      // 点击圆点时滚动到对应的卡片
      indicator.addEventListener('click', () => {
        const cardWidth = cards[0].offsetWidth + 20; // 包含gap的宽度
        cardContainer.scrollTo({
          left: index * cardWidth,
          behavior: 'smooth' // 平滑滚动
        });
      });
    });

    const updateIndicators = () => {
      const scrollLeft = cardContainer.scrollLeft;
      const cardWidth = cards[0].offsetWidth + 20; // 包含gap的宽度
      const currentIndex = Math.round(scrollLeft / cardWidth);
      indicators.querySelectorAll('.indicator').forEach((indicator, index) => {
        indicator.classList.toggle('active', index === currentIndex);
      });
    };

    // 监听卡片容器的滚动事件
    cardContainer.addEventListener('scroll', updateIndicators);

    // 监听窗口大小改变事件，重新计算视口高度
    window.addEventListener('resize', this.setCardHeight);
  },
  methods: {
    setCardHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      const cards = document.querySelectorAll('.card');
      cards.forEach(card => {
        card.style.height = `calc(var(--vh, 1vh) * 80)`; // 动态计算 80vh
      });
    },
    initializeCards() {
      const cards = document.querySelectorAll('.card');
      cards.forEach(card => {
        const title = card.getAttribute('data-title');
        const subtitle = card.getAttribute('data-subtitle');
        const align = card.getAttribute('data-align');
        const badge = card.getAttribute('data-badge');
        const link = card.getAttribute('data-link'); // 获取跳转链接

        const content = document.createElement('div');
        content.classList.add('card-content', align);

        if (badge) {
          const badgeElem = document.createElement('div');
          badgeElem.classList.add('badge', `badge-${badge}`);
          switch (badge) {
            case 'latest':
              badgeElem.textContent = '最新发布';
              break;
            case 'comingsoon':
              badgeElem.textContent = '即将发布';
              break;
            case 'news':
              badgeElem.textContent = '新闻';
              break;
            case 'event':
              badgeElem.textContent = '特别活动';
              break;
          }
          content.appendChild(badgeElem);
        }

        const titleElem = document.createElement('div');
        titleElem.classList.add('title');
        titleElem.textContent = title;

        const subtitleElem = document.createElement('div');
        subtitleElem.classList.add('subtitle');
        subtitleElem.textContent = subtitle;

        content.appendChild(titleElem);
        content.appendChild(subtitleElem);

        // 如果有链接，则添加提示文字和点击事件
        if (link) {
          const learnMoreElem = document.createElement('div');
          learnMoreElem.classList.add('learn-more');
          learnMoreElem.textContent = '轻点以深入了解';
          content.appendChild(learnMoreElem);

          card.style.cursor = 'pointer'; // 鼠标变为点击样式
          card.addEventListener('click', () => {
            this.$router.push(link); // 使用 Vue Router 的编程式导航
          });
        }

        card.appendChild(content);
      });
    }
  }
};
</script>
  
  <style scoped>
  .scroll-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden; /* 隐藏水平滚动条 */
  }
  .card-container {
    display: flex;
    gap: 20px; /* 卡片之间的间距 */
    overflow-x: auto; /* 横向滚动 */
    padding: 0 10vw; /* 安全区 */
    box-sizing: border-box;
    width: 100%;
    scroll-snap-type: x mandatory; /* 使滚动有吸附效果 */
  }
  .card-container::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
  }
  .card {
    width: calc(100vw - 10vw); /* 卡片宽度为设备屏幕宽度减去安全区 */
    height: 80vh; /* 初始高度设置为80vh，会被JavaScript覆盖 */
    background-size: cover;
    background-position: center;
    border-radius: 5px; /* 圆角 */
    flex: 0 0 auto; /* 保持卡片宽度不变 */
    scroll-snap-align: center; /* 滚动对齐 */
    position: relative; /* 相对定位以包含内部绝对定位元素 */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  ::v-deep .card-content {
    position: absolute;
    color: white;
  }
  ::v-deep .learn-more {
    color: #007bff;
    margin-top: 10px
  }
  ::v-deep .title {
    font-family: 'MiSans-Bold', Arial, sans-serif;
    font-size: 60px; /* 加大标题字号 */
  }
  ::v-deep .subtitle {
    font-family: 'MiSans', Arial, sans-serif;
    font-size: 20px;
  }
  ::v-deep .badge {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  ::v-deep .badge-latest {
    background-color: yellow;
    color: black;
  }
  ::v-deep .badge-comingsoon {
    background-color: rgb(32, 136, 255);
    color: black;
  }
  ::v-deep .badge-news {
    background-color: green;
    color: white;
  }
  ::v-deep .badge-event {
    background-color: purple;
    color: white;
  }
  ::v-deep .center {
    justify-content: center;
    align-items: center;
  }
  ::v-deep .top-left {
    top: 10px;
    left: 10px;
    text-align: left;
  }
  ::v-deep .top-right {
    top: 10px;
    right: 10px;
    text-align: right;
  }
  ::v-deep .bottom-left {
    bottom: 10px;
    left: 10px;
    text-align: left;
  }
  ::v-deep .bottom-right {
    bottom: 10px;
    right: 10px;
    text-align: right;
  }
  .indicator-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 100px;
  }
  ::v-deep .indicator {
    width: 10px;
    height: 10px;
    background-color: grey;
    border-radius: 50%;
    margin: 0 5px;
    transition: background-color 0.3s ease;
    cursor: pointer; /* 添加点击手型光标 */
  }
  ::v-deep .indicator.active {
    background-color: white;
  }
  @media (max-width: 767px) {
    ::v-deep .title {
      font-size: 45px;
    }
    ::v-deep .subtitle {
      font-size: 15px;
    }
  }
  /* 小型卡片 */
.small-scroll-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
}

.small-card-container {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 0 10vw;
  box-sizing: border-box;
  width: 100%;
  scroll-snap-type: x mandatory;
}

.small-card {
  width: calc(50vw - 5vw); /* 小卡片的宽度 */
  height: 40vh; /* 小卡片的高度 */
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  flex: 0 0 auto;
  scroll-snap-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
  </style>  