<template>
  <div class="placeholder">
    <div id="AuroraPlayer" ref="playerContainer"></div>
  </div>
</template>

<script>
import AuroraPlayer from 'auroraplayer';

export default {
  name: 'PlayVideo',
  data() {
    return {
      vid: '',
    };
  },
  mounted() {
    document.title = "活动回放 - VDS·欢乐，正发生。";

    this.vid = this.$route.query.vid;

    if (!this.vid) {
      console.error('vid 参数缺失');
      return;
    }

    this.initPlayer();
  },
  methods: {
    initPlayer() {
      fetch(`https://static.vdsentnet.com/pubapi/get_video_url.php?vid=${encodeURIComponent(this.vid)}`)
        .then(response => response.json())
        .then(data => {
          if (data.videourl) {
            this.player = new AuroraPlayer({
              container: this.$refs.playerContainer,
              video: {
                url: data.videourl,
              },
              preload: 'auto',
              // 其他配置项
            });
          } else {
            console.error('获取视频 URL 出错:', data.error);
          }
        })
        .catch(error => {
          console.error('获取视频 URL 时发生错误:', error);
        });
    }
  },
  beforeUnmount() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style scoped>
.placeholder {
  width: 100%;
  height: 90vh;
}

#AuroraPlayer {
  width: 100%;
  height: 100%;
}
</style>